<template>
  <div>
    <div class="flex">
      <div class="summary">
        <b>{{ $t("funnel_budget.summary") }}</b>
        <li>
          <b>{{ $t("funnel_budget.meters") }}</b> {{ funnelData.meters }} m²
        </li>

        <li>
          <b>{{ $t("funnel_budget.city") }}</b> {{ getCityName() }}
        </li>
        <li>
          <b>{{ $t("funnel_budget.pickup") }}</b> {{ getPickup() }}
        </li>
        <li>
          <b>{{ $t("funnel_budget.prices") }}</b> {{ getMonthly() }}/
          {{ $t("funnel_budget.month") }}
        </li>
        <li>
          <b>{{ $t("funnel_budget.delivery") }}</b> {{ getCityDelivery() }}
        </li>

        <li v-if="funnelData.box">
          &#10004; {{ $t("funnel_budget.add_service2") }}
        </li>
        <li v-if="funnelData.stairs">
          &#10004; {{ $t("funnel_budget.add_service4") }}
        </li>
        <li v-if="funnelData.disassemble">
          &#10004; {{ $t("funnel_budget.add_service3") }}
        </li>
        <li v-if="funnelData.furnishings">
          &#10004; {{ $t("funnel_budget.add_service1") }}
        </li>
        <li>
          <b>{{ $t("funnel_budget.date") }}</b> {{ funnelData.date }}
        </li>
      </div>

      <div class="formArea">
        <fieldset class="form">
          <b>{{ $t("funnel_budget.info") }}</b>

          <div class="flex">
            <div>
              <input
                type="text"
                id="adress"
                :placeholder="$t('funnel_budget.address')"
                v-model.trim="$v.adress.$model"
                :class="{ errorCell: $v.adress.$error }"
              />

              <div v-if="$v.adress.$error" class="errors">
                <span
                  :key="index"
                  v-for="(item, index) in Object.keys($v.adress.$params)"
                >
                </span>
              </div>
            </div>
            <div>
              <input
                type="number"
                :placeholder="$t('funnel_budget.number')"
                v-model="funnelData.number"
                :class="{ errorCell: $v.number.$error }"
              />
              <div v-if="$v.number.$error" class="errors">
                <span
                  :key="index"
                  v-for="(item, index) in Object.keys($v.number.$params)"
                >
                </span>
              </div>
            </div>
          </div>

          <div class="flex">
            <div>
              <input
                type="text"
                :placeholder="$t('funnel_budget.ladden')"
                v-model="funnelData.ladden"
                :class="{ errorCell: $v.ladden.$error }"
              />
              <div v-if="$v.ladden.$error" class="errors">
                <span
                  :key="index"
                  v-for="(item, index) in Object.keys($v.ladden.$params)"
                >
                </span>
              </div>
            </div>
            <div>
              <input
                type="text"
                :placeholder="$t('funnel_budget.portal')"
                v-model="funnelData.portal"
                :class="{ errorCell: $v.portal.$error }"
              />
              <div v-if="$v.portal.$error" class="errors">
                <span
                  :key="index"
                  v-for="(item, index) in Object.keys($v.portal.$params)"
                >
                </span>
              </div>
            </div>
            <div>
              <input
                type="text"
                :placeholder="$t('funnel_budget.flat')"
                v-model.trim="$v.flat.$model"
                :class="{ errorCell: $v.flat.$error }"
              />
              <div>
                <div v-if="$v.flat.$error" class="errors">
                  <span
                    :key="index"
                    v-for="(item, index) in Object.keys($v.flat.$params)"
                  >
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div>
              <input
                type="number"
                :placeholder="$t('funnel_budget.postal_code')"
                v-model="funnelData.postcode"
                :class="{ errorCell: $v.postcode.$error }"
              />
              <div v-if="$v.postcode.$error" class="errors">
                <span
                  :key="index"
                  v-for="(item, index) in Object.keys($v.locality.$params)"
                >
                </span>
              </div>
            </div>
            <div>
              <input
                type="text"
                :placeholder="$t('funnel_budget.municipality')"
                v-model.trim="$v.locality.$model"
                :class="{ errorCell: $v.locality.$error }"
              />
              <div>
                <div v-if="$v.locality.$error" class="errors">
                  <span
                    :key="index"
                    v-for="(item, index) in Object.keys($v.locality.$params)"
                  >
                  </span>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            :disabled="loading"
            class="sendButton"
            v-on:click.prevent="submit"
            :class="loading ? 'sendingData' : 'normalCursor'"
          >
            {{ $t("funnel_budget.send") }}
          </button>
        </fieldset>
      </div>
    </div>

    <div class="footer">
      <button class="backButton" v-on:click="decrement">
        &lt; {{ $t("funnel_budget.back") }}
      </button>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import monthly from "@/utils/funnelPrices.js";
import { mapState } from "vuex";
import api from "@/api";

export default {
  name: "B2bBudgetForm",
  components: {},
  data: () => ({
    count: 4,
    error: "",
    loading: false,
  }),

  computed: {
    ...mapState("funnel", {
      funnelData: (state) => state.funnelData,
    }),
    adress: {
      get() {
        return this.funnelData.adress;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { adress: value });
      },
    },
    postcode: {
      get() {
        return this.funnelData.postcode;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { postcode: value });
      },
    },
    locality: {
      get() {
        return this.funnelData.locality;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { locality: value });
      },
    },
    flat: {
      get() {
        return this.funnelData.flat;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { flat: value });
      },
    },
    ladden: {
      get() {
        return this.funnelData.ladden;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { ladden: value });
      },
    },
    number: {
      get() {
        return this.funnelData.number;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { number: value });
      },
    },
    portal: {
      get() {
        return this.funnelData.portal;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { portal: value });
      },
    },
  },
  validations: {
    adress: {
      required,
    },
    locality: {
      required,
    },
    postcode: {
      required,
    },
    portal: {},
    flat: {},
    ladden: {},
    number: {
      required,
    },
  },

  methods: {
    decrement() {
      this.$emit("changePane", {
        count: this.count - 2,
      });
    },
    getCityDelivery() {
      return monthly[this.funnelData.city][this.funnelData.meters]["delivery"];
    },
    getCityName() {
      return monthly[this.funnelData.city]["name"];
    },
    getMonthly() {
      return monthly[this.funnelData.city][this.funnelData.meters]["storage"];
    },
    getPickup() {
      return monthly[this.funnelData.city][this.funnelData.meters]["pickup"];
    },
    async submit() {
      const datas = {
        deal_id: this.$route.params.deal_id,
        comment: `<li>Recogida Fecha: ${this.funnelData.date} </li> 
           <li>City: ${this.getCityName(this.funnelData)} </li>
           <li>Precio: ${this.getMonthly(this.funnelData)} </li>
           <li>${this.funnelData.adress} ${this.funnelData.number} ${
          this.funnelData.ladden || ""
        } ${this.funnelData.portal || ""} ${this.funnelData.flat || ""} ${
          this.funnelData.postcode
        } ${this.funnelData.locality} <li> Embalaje de cajas: ${
          this.funnelData.box || "No"
        }  </li>
        <li>Carga/descarga por escaleras: ${
          this.funnelData.stairs || "No"
        }   </li>
        <li> Desmontaje de muebles: ${
          this.funnelData.disassemble || "No"
        }  </li>
        <li> Embalaje de muebles: ${this.funnelData.furnishings || "No"}   </li>
          `,
      };
      this.$v.$touch();

      this.loading = true;

      if (this.$v.$invalid) {
        this.$toastr.e(this.$t("funnel_budget.field_error"));
      } else {
        try {
          await api.funnel.pickupReserve(datas);
          this.$emit("changePane", {
            count: this.count,
          });
        } catch (err) {
          this.$toastr.e(this.$t("funnel_budget.generic_error"));
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sendingData {
  cursor: wait;
  opacity: 0.5;
}

b {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

li {
  list-style: none;
  text-align: left;
  margin-left: 9px;
}
.summary {
  background-color: #e7edfa;
  border-radius: 25px;
  padding: 10px;
}

* {
  color: #140c40;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 185.69%;
}

span {
  display: block;
  text-align: left;
}

.errorCell {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 1);
  box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 1);
}

a {
  color: #4760ff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
a:hover {
  color: #140c40;
}

label {
  font-size: 14px;
}

h2 {
  text-align: left;
}
input {
  height: 50px;
  background-color: white;
  border-radius: 25px;
  mix-blend-mode: normal;
  border: none;
  width: 90%;
  margin: 10px;
}
::placeholder {
  font-size: 0.8em;
}
input[type="checkbox"] {
  height: auto;
  width: auto;
  cursor: pointer;
  margin: 5px 5px 0 0px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
}
.formArea {
  text-align: center;
  justify-content: center;
}
.form {
  background-color: #e7edfa;
  border-radius: 25px;
  align-items: center;
  margin: 0px 10px 10px 10px;
  height: 100%;
  line-height: 150%;
  width: 400px;
  padding: 10px;
}

.sendButton {
  width: 149px;
  height: 45px;
  left: 906px;
  top: 770px;
  color: #fff;
  background: #140c40;
  border: 1px solid #140c40;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.normalCursor {
  cursor: pointer;
}

.footer {
  display: flex;
}

.backButton {
  font-style: normal;
  font-size: 18px;
  line-height: 185.69%;
  left: 2%;
  position: absolute;
  cursor: pointer;
  bottom: 2%;
  background: none;
  color: #140c40;
  border: none;
  text-align: right;
  max-width: fit-content;
  box-shadow: none;
  font-weight: 700;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .flex {
    display: inline;
    flex-direction: column;
    width: 100%;
  }
  input {
    margin: 0 0 10 0;
  }
  .form {
    width: 100%;
    margin: 0;
  }
  .summary {
    margin: 20px 10px;
    padding: 0;
  }
  .formArea {
    margin: 20px 10px;
  }
  .footer {
    display: block;
  }

  .backButton {
    position: relative;
    left: -35%;
    bottom: 2%;
    max-width: none;
  }
}
</style>
