<template>
  <div>
    <div class="servicesContainer">
      <div class="leftPanel">
        <fieldset>
          <h2>{{ $t("funnel_budget.city") }}</h2>
          <div v-for="item in country" :key="item.id">
            <input
              type="radio"
              class="text-radio"
              autocomplete="off"
              required
              checked
              :value="item.code"
              :id="item.name"
              v-model="funnelData.city"
            />
            <label class="radio-label" :for="item.name">
              {{ item.name }}
            </label>
          </div>
        </fieldset>
      </div>
      <div class="rightPanel">
        <fieldset>
          <h2>{{ $t("funnel_budget.space") }}</h2>
          <input
            type="radio"
            id="1"
            value="1"
            required
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="1">1 m²</label>
          <input
            type="radio"
            id="2"
            value="2"
            required
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="2">2 m²</label>
          <input
            type="radio"
            id="3"
            value="3"
            required
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="3">3 m²</label>
          <input
            type="radio"
            id="4"
            value="4"
            required
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="4">4 m²</label>
          <input
            type="radio"
            required
            id="5"
            value="5"
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="5">5 m²</label>
          <input
            type="radio"
            required
            id="6"
            value="6"
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="6">6 m²</label>
          <input
            type="radio"
            required
            id="7"
            value="7,5"
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="7">7,5 m²</label>
          <input
            type="radio"
            required
            id="9"
            value="9"
            name="meters"
            class="text-radio"
            autocomplete="off"
            v-model="funnelData.meters"
          />
          <label class="radio-label" for="9">9 m²</label>
          <div>
            <input
              type="radio"
              required
              id="custom"
              value="custom"
              name="meters"
              class="text-radio"
              autocomplete="off"
              v-model="funnelData.meters"
            />
            <label class="radio-label fullLine" for="custom">
              {{ $t("funnel_budget.custom_space") }}</label
            >
          </div>
        </fieldset>
      </div>
    </div>

    <div class="footer">
      <div class="controls">
        <button
          class="nextButton"
          v-on:click="incrementCount"
          :disabled="!meters || !city"
        >
          {{ $t("funnel_budget.next") }} >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "B2bSelectCity",
  props: {
    country: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    count: 2,
  }),

  methods: {
    incrementCount() {
      this.$emit("changePane", {
        count: this.count,
      });
    },
  },
  computed: {
    ...mapState("funnel", {
      funnelData: (state) => state.funnelData,
      cities: (state) => state.cities,
    }),

    city: {
      get() {
        return this.funnelData.city;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { city: value });
      },
    },
    meters: {
      get() {
        return this.funnelData.meters;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { meters: value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #140c40;
  font-family: "Roboto", sans-serif;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
input[type="radio"]:hover + .radio-label {
  border: 3px solid #4760ff;
  box-sizing: border-box;
}

input[type="radio"]:checked + .radio-label {
  background-color: #4760ff;
  border: 3px solid #4760ff;
  color: white;
}
.radio-label {
  display: inline-block;
  padding: 10px 0;
  transition: all 0.01s;
  background-color: #fff;
  text-align: center;
  margin: 5px;
  border-radius: 25px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1rem;
  user-select: none;
  width: 110px;
  border: 3px white solid;
}

.fullLine {
  width: 70%;
}

.text-radio {
  display: none;
}

.rightPanel {
  display: flex;
  background-color: #e7edfa;
  border-radius: 25px;
  width: 320px;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding: 5px 0 5px 0;
  margin: 0 10px 0 10px;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  background-color: #e7edfa;
  border-radius: 25px;
  width: 320px;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding: 5px 0 5px 0;
  margin: 0 10px 0 10px;
}

.leftPanel > *:not(p) {
  border-radius: 25px;
  width: 50%;
  border: none;
}

.rightPanel > *:not(p) {
  border-radius: 25px;
  border: none;
}

.controls {
  display: flex;
}

.nextButton {
  font-style: normal;
  font-size: 18px;
  line-height: 185.69%;
  position: absolute;
  right: 2%;
  cursor: pointer;
  bottom: 2%;
  background: none;
  color: #140c40;
  border: none;
  text-align: right;
  max-width: fit-content;
  box-shadow: none;
  font-weight: 700;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--blue-1 {
    background-color: #4760ff;
    border-color: #4760ff;
    color: #fff;
    margin-left: auto;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .servicesContainer {
    display: inline;
    flex-direction: column;
    width: 100%;
  }

  .footer {
    display: inline;
    text-align: right;
  }
  .leftPanel {
    margin-bottom: 20px;
    max-width: 350px;
  }
  .rightPanel {
    display: flex;
    flex-direction: row;
    max-width: 350px;
  }
  .controls {
    display: block;
  }
  .nextButton {
    position: relative;
    max-width: none;
  }
}
</style>
