<template>
  <div>
    <img src="@/assets/logo.jpg" class="logo" />
    <div class="stepper">
      <div class="progress_bar">
        <div class="number completed_number">
          <p :class="{ mask: step > 1 }">1</p>
          <p :class="{ mask: step == 1 }">&#10004;</p>
        </div>
        <div class="line"></div>
        <div
          class="number"
          :class="{ number: step != 2, completed_number: step >= 2 }"
        >
          <p :class="{ mask: step > 2 }">2</p>
          <p :class="{ mask: step <= 2 }">&#10004;</p>
        </div>
        <div class="line"></div>
        <div
          class="number"
          :class="{ number: step != 3, completed_number: step >= 3 }"
        >
          <p :class="{ mask: step > 3 }">3</p>
          <p :class="{ mask: step <= 3 }">&#10004;</p>
        </div>
      </div>
    </div>

    <div class="stepper-pane" v-if="step === 1">
      <h1>{{ $t("funnel_budget.sentence1") }}</h1>
      <h2>{{ $t("funnel_budget.subsentence1") }}</h2>
      <div class="budget_services">
        <B2bSelectCity
          :country="country"
          v-on:changePane="getNavigationState"
        />
      </div>
    </div>

    <div class="stepper-pane" v-if="step === 2">
      <h1>{{ $t("funnel_budget.sentence2") }}</h1>
      <h2>{{ $t("funnel_budget.subsentence2") }}</h2>
      <B2BAditionalService v-on:changePane="getNavigationState" />
    </div>

    <div class="stepper-pane" v-if="step === 3">
      <h1>{{ $t("funnel_budget.sentence3") }}</h1>
      <h2>{{ $t("funnel_budget.subsentence3") }}</h2>
      <B2bForm v-on:changePane="getNavigationState" />
    </div>

    <div class="stepper-pane" v-if="step === 4">
      <h1>{{ $t("funnel_budget.thanks") }}</h1>
    </div>
  </div>
</template>

<script>
import B2bSelectCity from "./B2bSelectCity.vue";
import B2BAditionalService from "./B2BAditionalService.vue";
import B2bForm from "./B2bBudgetForm.vue";
import { mapState, mapActions } from "vuex";
import { loadLanguageAsync } from "@/i18n";

export default {
  name: "B2bBody",
  components: {
    B2bSelectCity,
    B2BAditionalService,
    B2bForm,
  },
  async created() {
    const language = await this.getSiteLanguage();

    // Initialize site language
    loadLanguageAsync(language);

    this.country = this.cities.filter(
      (cities) => cities.country === language.toUpperCase()
    );
  },
  computed: {
    ...mapState("funnel", {
      funnelData: (state) => state.funnelData,
      cities: (state) => state.cities,
    }),
  },
  data() {
    return { step: 1, country: null };
  },

  methods: {
    getNavigationState(event) {
      this.step = event.count;
    },

    ...mapActions({
      getSiteLanguage: "languages/getSiteLanguage",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mask {
  display: none;
}
.logo {
  height: 80px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.budget_services {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
}

body {
  color: #ffffff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  border-radius: 25px;
  margin: 0;
  width: 100%;
}

.stepper-pane {
  text-align: center;
}

.number {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #4760ff;
  font-size: 25px;
  font-weight: 700;
  color: #4760ff;
}

.completed_number {
  background-color: #4760ff;
  color: white;
}

.line {
  background-color: #4760ff;
  height: 5px;
  width: 100px;
  border-radius: 25px;
}

.progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  width: 100%;
}

.progress_bar > * {
  margin: 5px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .stepper-pane {
    width: auto;
    height: auto;
  }

  .logo {
    text-align: center;
    display: inline;
  }
  body {
    display: block;
  }

  h1 {
    font-size: 1.6em;
  }

  h2 {
    font-size: 1em;
  }

  .stepper {
    width: 100%;
  }
}
</style>
