<template>
  <div>
    <div>
      <div class="servicesContainer">
        <div>
          <DatePicker
            :first-day-of-week="2"
            :disabled-dates="{ weekdays: days }"
            v-model="funnelData.date"
            :model-config="{
              type: 'string',
              mask: 'DD-MM-YYYY',
            }"
            :minDate="today"
            :attributes="attrs"
            class="calendarStyle"
          />
        </div>

        <div class="extraServiceCheck">
          <div>
            <li class="noMarginTop">
              <input
                class="switch"
                type="checkbox"
                id="furnishings"
                v-model="funnelData.furnishings"
              />
              <label for="furnishings">
                {{ $t("funnel_budget.add_service1") }}</label
              >
            </li>
            <li>
              <input
                type="checkbox"
                id="box"
                v-model="funnelData.box"
                class="switch"
              />
              <label for="box"> {{ $t("funnel_budget.add_service2") }}</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="disassemble"
                v-model="funnelData.disassemble"
                class="switch"
              />
              <label for="disassemble">{{
                $t("funnel_budget.add_service3")
              }}</label>
            </li>

            <li>
              <input
                type="checkbox"
                class="switch"
                id="stairs"
                v-model="funnelData.stairs"
              />
              <label for="stairs">
                {{ $t("funnel_budget.add_service4") }}</label
              >
            </li>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="backButton" v-on:click="changeBackPanel">
        &lt; {{ $t("funnel_budget.back") }}
      </button>

      <button class="nextButton" v-on:click="changeNextPanel" :disabled="!date">
        {{ $t("funnel_budget.next") }} >
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import monthly from "@/utils/funnelPrices.js";

export default {
  name: "B2bSelectCity",
  components: {
    DatePicker,
  },
  data: () => ({
    count: 3,
    today: new Date().toISOString().slice(0, 10),
    days: "",

    attrs: [
      {
        key: "today",
        highlight: {
          fillMode: "outline",
        },
        dates: new Date(),
      },
    ],
  }),

  computed: {
    ...mapState("funnel", {
      funnelData: (state) => state.funnelData,
    }),
    furnishings: {
      get() {
        return this.funnelData.furnishings;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { furnishings: value });
      },
    },
    box: {
      get() {
        return this.funnelData.box;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { box: value });
      },
    },
    stairs: {
      get() {
        return this.funnelData.stairs;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { stairs: value });
      },
    },
    disassemble: {
      get() {
        return this.funnelData.disassemble;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { disassemble: value });
      },
    },
    date: {
      get() {
        return this.funnelData.date;
      },
      set(value) {
        this.$store.commit("funnel/updatefunnelData", { date: value });
      },
    },
  },
  methods: {
    changeColor() {
      this.isActive = !this.isActive;
    },

    changeNextPanel() {
      this.$emit("changePane", {
        count: this.count,
      });
    },
    changeBackPanel() {
      this.$emit("changePane", {
        count: this.count - 2,
      });
    },
    getWorkDay() {
      return monthly[this.funnelData.city]["days"];
    },
  },
  mounted() {
    this.days = this.getWorkDay();
  },
};
</script>

<style lang="scss" scoped>
$dark: #140c40;
$blue: #4760ff;
$dark_blue: #e7edfa;

.calendarStyle {
  color: #4760ff;
  background-color: #e7edfa;
  border-radius: 25px;
  width: 300px;
}

.noMarginTop {
  margin-top: 0px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #4760ff;
    --active-inner: #e7edfa;
    --focus: 2px #4760ff;
    --border: #e7edfa;
    --border-hover: #4760ff;
    --background: #140c40;
    --disabled: #f6f8ff;
    --disabled-inner: #e7edfa;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 1.1rem;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type="checkbox"] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

li {
  align-items: center;
  border-radius: 10px;
  background-color: $dark_blue;
  display: flex;
  font-weight: 700;
  height: 70px;
  padding: 10px;
  margin: 10px;
  width: auto;
}

.nextButton {
  font-style: normal;
  font-size: 18px;
  line-height: 185.69%;
  position: absolute;
  right: 2%;
  cursor: pointer;
  bottom: 2%;
  background: none;
  color: #140c40;
  border: none;
  text-align: right;
  max-width: fit-content;
  box-shadow: none;
  font-weight: 700;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--blue-1 {
    background-color: #4760ff;
    border-color: #4760ff;
    color: #fff;
    margin-left: auto;
  }
}

.backButton {
  font-style: normal;
  font-size: 18px;
  line-height: 185.69%;
  left: 2%;
  position: absolute;
  cursor: pointer;
  bottom: 2%;
  background: none;
  color: #140c40;
  border: none;
  text-align: right;
  max-width: fit-content;
  box-shadow: none;
  font-weight: 700;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.extraServiceCheck {
  display: flex;
  border-radius: 25px;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin: 0 10px 0 10px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .servicesContainer {
    display: inline;
    flex-direction: column;
    width: 100%;
  }

  .extraServiceCheck {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .noMarginTop {
    margin-top: 10px;
  }

  .footer {
    display: flex;
    text-align: right;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .nextButton {
    position: relative;
    max-width: none;
  }
  .backButton {
    position: relative;
    bottom: 2%;
  }

  ul {
    width: auto;
  }
}
</style>
